import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"
import { Link } from 'react-router-dom';
import Header from "../../common/header/Header";




  

const Hero = () => {
 
  return (
    <>
    
    <div className='container'>
    <div className='row'>
          
           <br></br> <br></br> <br></br>
           <br></br>
           <br></br>
           <br></br>
          
            <h1>WELCOME TO Nayel IT School</h1>
            <h2>Job-ready training programs</h2> <br></br>
            <p> we offer a diverse range of 
              online courses and job-ready training programs taught by industry experts to help you achieve your career goals.</p>

          </div>    
             
<div className='button'>
<Link to='/register'>
           
<button > 
               APPLY NOW <i  className='fa fa-long-arrow-alt-right'></i>
              </button></Link> 
              <Link to="/courses">
              <button>
                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
              </button> 
                </Link>  
            </div>
          </div>
      <section className='hero'>
        
    
        <div className='container'>
        
        </div>
       
      </section>
      <div className="margin"></div>
     
      
    </>
  )
}

export default Hero
