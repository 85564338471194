import React from "react"
import "./Register.css"
import Back from "../common/back/Back"

const Register = () => {
  return (
    <>
 
<Back title='Register' />
<div className="Container" id="contact-form">
    <div >
    <iframe src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAO__QCxR_BUM0ZRUUYxN0pUVE5EVTNQUUdaV1o4T1dOOC4u&embed=true" width="100%" height="480px" className="Content" />
    </div>
    

    </div>

</>
)
}

export default Register