export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Online Courses",
    desc: "At Nayel IT School, we offer a variety of online courses to help "+
    "you advance your knowledge and skills from the comfort of your own home.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/programming.png",
    title: "Job-Ready Course",
    desc: "At Nayel IT School, we offer job-ready courses that are designed to equip you with the skills"+ 
    "and knowledge needed to succeed in today's competitive job market. ",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Learn with Expert",
    desc: "At Nayel IT School, we believe in learning from the best. That's why we offer courses taught "+
    "by industry experts who are experienced in their field and passionate about sharing their knowledge with others. ",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover: "../images/courses/c1.png",
    coursesName: "Software Developer",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Nayel IT School",
        totalTime: "5 Months",
      },
    ],
   // priceAll: "$100 All Course",
   // pricePer: "$15 per month",
  },
  {
    id: 2,
    cover: "../images/courses/c2.png",
    coursesName: "SQL/BI Developer",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Nayel IT School",
        totalTime: "5 Months",
      },
    ],
    //priceAll: "$200 All Course",
    //pricePer: "$25 per month",
  },
  {
    id: 3,
    cover: "../images/courses/c3.png",
    coursesName: "Web Developers",
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Nayel IT School",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    //priceAll: "$50 All Course",
    //pricePer: "$5 per month",
  },
 
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
] 
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
