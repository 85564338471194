import React from "react"
import { Link } from "react-router-dom"
import Back from "../common/back/Back"
import "./contact.css"

const Contact = () => {
  const map = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2605.583731529764!2d-123.00206798460533!3d49.227421079325374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548676595c5fb407%3A0x70139844bf95bfa5!2sMetropolis%20at%20Metrotown!5e0!3m2!1sen!2sca!4v1679548232646!5m2!1sen!9sca" '
  return (
    <>
   
      <Back title='Contact us' />
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
            <iframe src={map}></iframe>
          </div>
          <div className='right row'>
            <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>

            <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>4700 Kingsway, Burnaby (Great Vancouver Area), BC , Canada</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p> info@nayelitschool.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p> +1 6043694547</p>
              </div>
            </div>

            <form action='' >
              <div className='flexSB'>
                <input type='text' placeholder='Name' />
                <input type='email' placeholder='Email' />
              </div>
              <input type='text' placeholder='Subject' />
              <textarea cols='30' rows='10'>
                Create a message here...
              </textarea>
              </form>
             <Link to="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAO__QCxR_BUQ1lZR0tEV1lZVEtVMUw2OUQxUTdRNlRPMC4u">
               <button className='primary-btn'>SEND MESSAGE</button></Link>
            
           
            <h3>Follow us here</h3>
            <span> 
          <i>info@nayelitschool.com / 6043694547</i>

          <div className='social'>

            <a href="https://www.facebook.com/profile.php?id=100091272456094"><i className='fab fa-facebook-f icon'></i></a>
            <i className='fab fa-instagram icon'></i>
            <a href="https://twitter.com/NayelITSchool"><i className='fab fa-twitter icon'></i></a>
            <a href="https://www.youtube.com/@NayelITSchool-je3vg"><i className='fab fa-youtube icon'></i></a>
            </div>
          </span>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
