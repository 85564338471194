import React from "react"
import AboutCard from "../about/AboutCard"
import Hblog from "./Hblog"
import HAbout from "./HAbout"
import Hero from "./hero/Hero"
import Hprice from "./Hprice"
import Testimonal from "./testimonal/Testimonal"
import Register from "../register/register"
import Contact from "../contact/Contact"


const Home = () => {
  return (
    <>
    
      <Hero />
     
      <AboutCard />
      <HAbout />
      
      
      
      
    </>
  )
}

export default Home

//<Testimonal />