import React from "react"
// <h1>Nayel IT School</h1>
//<span>Job-Rready Courses</span>
const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
        <a href="/">  <img src='./images/logo.png' alt='' width={300} /></a>
           
            
          </div>
          <i>info@nayelitschool.com / 6043694547</i>
          <div className='social'>
            <a href="https://www.facebook.com/profile.php?id=100091272456094"><i className='fab fa-facebook-f icon'></i></a>
            <i className='fab fa-instagram icon'></i>
            <a href="https://twitter.com/NayelITSchool"><i className='fab fa-twitter icon'></i></a>
            <a href="https://www.youtube.com/@NayelITSchool-je3vg"><i className='fab fa-youtube icon'></i></a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
